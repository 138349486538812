<!--
 * @Author: your name
 * @Date: 2021-02-27 10:33:34
 * @LastEditTime: 2021-03-24 10:35:17
 * @LastEditors: Please set LastEditors
 * @Description: 财务日报
 * @FilePath: \sd-vue-admin\src\views\project\report\manage\dayList\components\FinancialDaily.vue
-->
<template>
  <div>
    <el-row>
      <el-form ref="form" inline :model="form">
        <el-form-item prop="start_date">
          <el-date-picker
            v-model="form.start_date"
            type="date"
            placeholder="选择日期"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            style="width: 150px"
          ></el-date-picker>
        </el-form-item>
        <el-form-item prop="depart_id">
          <el-select
            v-model="form.depart_id"
            placeholder="请选择部门"
            style="width: 120px"
          >
            <el-option
              v-for="(item, index) in department"
              :key="index"
              :label="item.text"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="staff_id">
          <el-select
            v-model="form.staff_id"
            placeholder="请选择业务员"
            style="width: 120px"
          >
            <el-option
              v-for="(item, index) in userdata"
              :key="index"
              :label="item.user_name"
              :value="item.user_id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handlerInquire">查 询</el-button>
          <el-button type="warning" @click="handlerReset">重 置</el-button>
          <slot
            name="exportBtn"
            :start_date="form.start_date"
            :depart_id="form.depart_id"
            :staff_id="form.staff_id"
          ></slot>
        </el-form-item>
      </el-form>
    </el-row>
    <el-row>
      <h3>合计：</h3>
      <el-table stripe :data="tableData.total" style="width: 50%">
        <el-table-column
          v-for="(list, index) in colemd"
          :key="index"
          :label="list.label"
          :align="list.align"
          :prop="list.prop"
          width=""
        ></el-table-column>
      </el-table>
      <h3>明细：</h3>
      <el-table
        stripe
        :data="tableData.detailed_total"
        :span-method="arraySpanMethod"
        style="width: 50%"
      >
        <el-table-column
          v-for="(list, index) in colemd1"
          :key="index"
          :label="list.label"
          :align="list.align"
          :prop="list.prop"
          width=""
        ></el-table-column>
      </el-table>
      <h3>交帐记录：</h3>
      <el-table stripe :data="tableData.log_detailed" style="width: 50%">
        <el-table-column
          v-for="(list, index) in colemd2"
          :key="index"
          :label="list.label"
          :align="list.align"
          :prop="list.prop"
          width=""
        ></el-table-column>
      </el-table>
    </el-row>
  </div>
</template>

<script>
  import { postAction } from '@/api/Employee'
  import { day_n } from '@/utils/Time'
  export default {
    components: {},
    data() {
      return {
        form: {
          start_date: '',
          depart_id: '', //部门ID
          staff_id: '', //业务员ID
        },
        tableData: {},
        userdata: [],
        department: [],
        url: {
          department: '/baseAdmin/depart/index',
          userlist: '/baseAdmin/common/user-list',
          list: '/reportAdmin/operate/finance-day',
        },
        colemd: [
          {
            order: 1,
            label: '项目',
            align: 'center',
            prop: '3',
            width: '',
          },
          {
            order: 2,
            label: '当日',
            align: 'center',
            prop: '0',
            width: '',
          },
          {
            order: 3,
            label: '前日',
            align: 'center',
            prop: '1',
            width: '',
          },
          {
            order: 4,
            label: '环比',
            align: 'center',
            prop: '2',
            width: '',
          },
        ],
        colemd1: [
          {
            order: 1,
            label: '类型',
            align: 'center',
            prop: '0',
            width: '',
          },
          {
            order: 2,
            label: '项目',
            align: 'center',
            prop: '1',
            width: '',
          },
          {
            order: 3,
            label: '单据',
            align: 'center',
            prop: '2',
            width: '',
          },
          {
            order: 4,
            label: '金额',
            align: 'center',
            prop: '3',
            width: '',
          },
        ],
        colemd2: [
          {
            order: 1,
            label: '部门',
            align: 'center',
            prop: 'depart_name',
            width: '',
          },
          {
            order: 2,
            label: '员工',
            align: 'center',
            prop: 'user_name',
            width: '',
          },
          {
            order: 3,
            label: '电话',
            align: 'center',
            prop: 'mobile',
            width: '',
          },
          {
            order: 4,
            label: '对账金额',
            align: 'center',
            prop: 'money',
            width: '',
          },
          {
            order: 5,
            label: '实交账',
            align: 'center',
            prop: 'receiv_send',
            width: '',
          },
        ],
        spanArr: [],
        spanArr1: [],
      }
    },

    computed: {},
    watch: {},
    created() {},
    mounted() {
      this.qianyitian()
      this.handlerdepartment()
      this.hanlderuserlist()
      this.handlerlist()
    },
    methods: {
      getSpanArr(data) {
        let Arr = new Array()
        let pos = 0
        let Arr1 = new Array()
        let pos1 = 0
        console.log(data, '执行了吗月报')
        for (var i = 0; i < data.length; i++) {
          if (i == 0) {
            Arr.push(1)
            pos = 0
            this.spanArr1.push(1)
            pos1 = 0
          } else {
            // 判断当前元素与上一个元素是否相同
            console.log(data[i], '元素月报')
            if (data[i][0] === data[i - 1][0]) {
              Arr[pos] += 1
              Arr.push(0)
            } else {
              Arr.push(1)
              pos = i
            }
            if (data[i][2] === data[i - 1][2]) {
              Arr1[pos1] += 1
              Arr1.push(0)
            } else {
              Arr1.push(1)
              pos1 = i
            }
          }
          console.log(this.spanArr)
        }
        this.spanArr1 = Arr1
        this.spanArr = Arr
      },

      arraySpanMethod({ row, column, rowIndex, columnIndex }) {
        // console.log({ row, column, rowIndex, columnIndex })
        if (columnIndex === 0) {
          const _row = this.spanArr[rowIndex]
          const _col = _row > 0 ? 1 : 0
          console.log(`rowspan:${_row} colspan:${_col}`)
          return {
            // [0,0] 表示这一行不显示， [2,1]表示行的合并数
            rowspan: _row,
            colspan: _col,
          }
        }
        /*
         * @Describe 去掉了这个第三列合并
         * @Date 21/9/6
         * @Modifier hhy
         * */
        // else if (columnIndex == 2) {
        //   const _row = this.spanArr1[rowIndex]
        //   const _col = _row > 0 ? 1 : 0
        //   console.log(`rowspan:${_row} colspan:${_col}`)
        //   return {
        //     // [0,0] 表示这一行不显示， [2,1]表示行的合并数
        //     rowspan: _row,
        //     colspan: _col,
        //   }
        // }
      },
      handlerReset() {
        this.$refs['form'].resetFields()
        this.handlerlist()
      },
      handlerInquire() {
        this.handlerlist()
      },
      handlerlist() {
        postAction(this.url.list, this.form)
          .then((res) => {
            console.log(res)
            this.tableData = res.data
            this.getSpanArr(this.tableData.detailed_total)
            this.getdetailed(this.tableData.log_detailed)
          })
          .catch((err) => {
            console.log(err)
          })
      },
      //部门
      handlerdepartment() {
        postAction(this.url.department, { is_close: 0 })
          .then((res) => {
            console.log('部门', res)
            this.department = res.data.list
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      //收款人
      hanlderuserlist() {
        postAction(this.url.userlist, {})
          .then((res) => {
            console.log('收款人', res)
            this.userdata = res.data
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      qianyitian() {
        this.form.start_date = day_n()[0]
      },
    },
  }
</script>
<style lang="scss" scoped></style>
