<!--
 * @Author: your name
 * @Date: 2021-03-03 14:58:20
 * @LastEditTime: 2021-03-29 17:27:33
 * @LastEditors: Please set LastEditors
 * @Description: 行动日报
 * @FilePath: \sd-vue-admin\src\views\project\report\manage\dayList\components\ActionDaily.vue
-->
<template>
  <div>
    <el-row>
      <el-form ref="form" inline :model="form">
        <el-form-item prop="start_date">
          <el-date-picker
            v-model="form.start_date"
            type="date"
            placeholder="选择日期"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            style="width: 150px"
          ></el-date-picker>
        </el-form-item>
        <el-form-item prop="depart_id">
          <el-select
            v-model="form.depart_id"
            placeholder="请选择部门"
            style="width: 120px"
          >
            <el-option
              v-for="(item, index) in department"
              :key="index"
              :label="item.text"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="staff_id">
          <el-select
            v-model="form.staff_id"
            placeholder="请选择员工"
            style="width: 120px"
          >
            <el-option
              v-for="(item, index) in yuangong"
              :key="index"
              :label="item.user_name"
              :value="item.user_id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handlerInquire">查 询</el-button>
          <el-button type="warning" @click="handlerReset">重 置</el-button>
          <slot
            name="exportBtn"
            :start_date="form.start_date"
            :depart_id="form.depart_id"
            :staff_id="form.staff_id"
          ></slot>
        </el-form-item>
      </el-form>
    </el-row>
    <el-row v-if="tableData && tableData.total">
      <h3>合计：</h3>
      <table border="1" width="600">
        <tr>
          <th>考勤人数</th>
          <td>{{ tableData.total.user_total }}</td>
          <th>拜访客户</th>
          <td>{{ tableData.total.visit_num }}</td>
        </tr>
        <tr>
          <th>考勤正常</th>
          <td>{{ tableData.total.user_status1 }}</td>
          <th>创建客户</th>
          <td>{{ tableData.total.create_num }}</td>
        </tr>
        <tr>
          <th>位置异常</th>
          <td>{{ tableData.total.position_num }}</td>
          <th>线路漏店</th>
          <td>{{ tableData.total.leak_num }}</td>
        </tr>
        <tr>
          <th>迟到</th>
          <td>{{ tableData.total.user_status3 }}</td>
          <th>位置异常</th>
          <td>{{ tableData.total.user_status2 }}</td>
        </tr>
        <tr>
          <th>早退</th>
          <td>{{ tableData.total.user_status4 }}</td>
          <th>拍照</th>
          <td>{{ tableData.total.photo_num }}</td>
        </tr>
        <tr>
          <th>缺勤</th>
          <td>{{ tableData.total.user_status5 }}</td>
          <th>下单</th>
          <td>{{ tableData.total.order_num }}</td>
        </tr>
        <tr>
          <th>请假</th>
          <td>{{ tableData.total.user_status6 }}</td>
        </tr>
      </table>
      <h3>考勤明细：</h3>
      <el-table stripe :data="tableData.attend_detailed" style="width: 80%">
        <el-table-column
          v-for="(list, index) in colemd"
          :key="index"
          :label="list.label"
          :align="list.align"
          :prop="list.prop"
          width=""
        ></el-table-column>
      </el-table>
      <h3>拜访明细：</h3>
      <el-table stripe :data="tableData.log_detailed">
        <el-table-column
          v-for="(list, index) in colemd1"
          :key="index"
          :label="list.label"
          :align="list.align"
          :prop="list.prop"
          width=""
        ></el-table-column>
      </el-table>
    </el-row>
  </div>
</template>

<script>
  import { postAction } from '@/api/Employee'
  import { day_n } from '@/utils/Time'
  export default {
    data() {
      return {
        form: {
          start_date: '',
          staff_id: '', //员工ID
          depart_id: '', //部门ID
        },
        tableData: {},
        userdata: [],
        department: [],
        supplier: [],
        Brand: [],
        warehouse: [],
        yuangong: [],
        url: {
          yuangong: '/baseAdmin/user/index',
          department: '/baseAdmin/depart/index',
          userlist: '/baseAdmin/common/user-list',
          supplier: '/purchaseAdmin/base/supp-list',
          Brand: '/baseAdmin/common/brand-list',
          warehouse: '/baseAdmin/common/depot-list',
          list: '/reportAdmin/operate/actor-day',
        },
        colemd: [
          {
            order: 1,
            label: '部门',
            align: 'center',
            prop: 'depart_name',
            width: '',
          },
          {
            order: 2,
            label: '姓名',
            align: 'center',
            prop: 'user_name',
            width: '',
          },
          {
            order: 3,
            label: '电话',
            align: 'center',
            prop: 'mobile',
            width: '',
          },
          {
            order: 4,
            label: '角色',
            align: 'center',
            prop: 'user_role',
            width: '',
          },
          {
            order: 5,
            label: '正常（天）',
            align: 'center',
            prop: 'user_status1',
            width: '',
          },
          {
            order: 6,
            label: '异常（次）',
            align: 'center',
            prop: 'user_status2',
            width: '',
          },
          {
            order: 7,
            label: '迟到（次）',
            align: 'center',
            prop: 'user_status3',
            width: '',
          },
          {
            order: 8,
            label: '早退（次）',
            align: 'center',
            prop: 'user_status4',
            width: '',
          },
          {
            order: 9,
            label: '缺勤（次）',
            align: 'center',
            prop: 'user_status5',
            width: '',
          },
          {
            order: 10,
            label: '请假（天）',
            align: 'center',
            prop: 'user_status6',
            width: '',
          },
        ],
        colemd1: [
          {
            order: 1,
            label: '部门',
            align: 'center',
            prop: 'depart_name',
            width: '',
          },
          {
            order: 2,
            label: '姓名',
            align: 'center',
            prop: 'user_name',
            width: '',
          },
          {
            order: 3,
            label: '电话',
            align: 'center',
            prop: 'mobile',
            width: '',
          },
          {
            order: 4,
            label: '拜访总家数',
            align: 'center',
            prop: 'visit_num',
            width: '',
          },
          {
            order: 5,
            label: '线路总家数',
            align: 'center',
            prop: 'line_cust_num',
            width: '',
          },
          {
            order: 6,
            label: '按线路拜访家数',
            align: 'center',
            prop: 'line_visit_num',
            width: '',
          },
          {
            order: 7,
            label: '线路漏店',
            align: 'center',
            prop: 'leak_num',
            width: '',
          },
          {
            order: 8,
            label: '异常',
            align: 'center',
            prop: 'position_num',
            width: '',
          },
        ],
        spanArr: [],
      }
    },

    computed: {},
    watch: {
      'form.keyword'(val) {
        if (val == '') {
          this.form.goods_id = ''
        }
      },
    },
    created() {},
    mounted() {
      this.handlerdepartment()
      this.qianyitian()
      this.hanlderuserlist()
      this.handlerlist()
      this.handlersupplier()
      this.handlerBrand()
      this.handleryuangong()
    },
    methods: {
      getSpanArr(data) {
        let Arr = new Array()
        let pos = 0
        console.log(data, '执行了吗')
        try {
          for (var i = 0; i < data.length; i++) {
            if (i == 0) {
              Arr.push(1)
              pos = 0
            } else {
              // 判断当前元素与上一个元素是否相同
              console.log(data[i].type, '元素')
              if (data[i].depot_name === data[i - 1].depot_name) {
                Arr[pos] += 1
                Arr.push(0)
              } else {
                Arr.push(1)
                pos = i
              }
            }
            console.log(Arr)
          }
          this.spanArr = Arr
        } catch (error) {}
      },

      arraySpanMethod({ row, column, rowIndex, columnIndex }) {
        // console.log({ row, column, rowIndex, columnIndex })
        if (columnIndex === 0) {
          let _row = this.spanArr[rowIndex]
          let _col = _row > 0 ? 1 : 0
          console.log(`rowspan:${_row} colspan:${_col}`)
          return {
            // [0,0] 表示这一行不显示， [2,1]表示行的合并数
            rowspan: _row,
            colspan: _col,
          }
        }
      },
      keyname(val) {
        console.log(val, '商品名称')
        this.form.keyword = val
      },
      selectGoods(row) {
        console.log('商品', row)
        if (row.goods_id == undefined) {
          this.form.goods_id = -1
        } else {
          this.form.goods_id = row.goods_id
        }
      },
      handlerReset() {
        this.$refs['form'].resetFields()
        this.handlerlist()
      },
      handlerInquire() {
        this.handlerlist()
      },
      handlerlist() {
        postAction(this.url.list, this.form)
          .then((res) => {
            console.log(res)
            this.tableData = res.data
            this.getSpanArr(this.tableData.detailed)
          })
          .catch((err) => {
            console.log(err)
          })
      },
      //仓库
      handlerwarehouse() {
        postAction(this.url.warehouse, { pageSize: -1 })
          .then((res) => {
            console.log(res, 'lalal')
            this.warehouse = res.data
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      //品牌
      handlerBrand() {
        postAction(this.url.Brand, { pageSize: -1 })
          .then((res) => {
            console.log(res, '品牌')
            this.Brand = res.data
          })
          .catch((err) => {
            console.log(err)
          })
      },
      //供应商
      handlersupplier() {
        postAction(this.url.supplier, {})
          .then((res) => {
            console.log(res, '供应商')
            this.supplier = res.data.rows
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      //部门
      handlerdepartment() {
        postAction(this.url.department, { is_close: 0 })
          .then((res) => {
            console.log('部门', res)
            this.department = res.data.list
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      //收款人
      hanlderuserlist() {
        postAction(this.url.userlist, {})
          .then((res) => {
            console.log('收款人', res)
            this.userdata = res.data
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      // 员工列表
      handleryuangong() {
        postAction(this.url.yuangong, { status: 0, pageSize: -1 })
          .then((res) => {
            console.log(res, '员工列表')
            this.yuangong = res.data
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      qianyitian() {
        this.form.start_date = day_n()[0]
      },
    },
  }
</script>
<style lang="scss" scoped>
  table,
  th,
  td {
    border-collapse: collapse;
    border: 1px solid black;
  }
  th,
  td {
    padding: 5px;
  }
</style>
